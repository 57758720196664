export default {
    ITEMS: [
        '2x4',
        'Amplifier',
        'Baseball Bat',
        'Battery',
        'Beer',
        'Bingo Ball Cage',
        'Blast Frequency Gun',
        'Bowie Knife',
        'Box of Nails',
        'Boxing Gloves',
        'Bucket',
        'Bull Skull',
        'Cement Saw',
        'Chainsaw',
        'Computer Case',
        'Construction Hat',
        'Drill Motor',
        'Dynamite',
        'Electric Chair',
        'Electric Guitar',
        'Fire Axe',
        'Fire Extinguisher',
        'Flashlight',
        'Football',
        'Fountain Firework',
        'Gasoline Canister',
        'Gems',
        'Goblin Mask',
        'Grenade',
        'Hunk of Meat',
        'Lawn Dart',
        'Lawn Mower',
        'Leaf Blower',
        'Leaf Rake',
        'Lizard Mask',
        'Lead Pipe',
        'LMG',
        'MMA Gloves',
        'Machete',
        'Merc Assault Rifle',
        'Motor Oil',
        'Nectar',
        'Newspaper',
        'Paddle',
        'Parasol',
        'Pitchfork',
        'Plates',
        'Power Drill',
        'Propane Tank',
        'Push Broom',
        'Pylon',
        'Queen',
        'Robot Bear',
        'Rocket Fireworks',
        'Saw Blade',
        'Servbot Mask',
        'Shotgun',
        'Sledge Hammer',
        'Spear',
        'Spray Paint',
        'Tennis Racquet',
        'Tiki Torch',
        'Toy Helicopter',
        'Toy Spitball Gun',
        'Training Sword',
        'Vacuum Cleaner',
        'Water Gun',
        'Wheelchair',
        'Whiskey',
    ],
    COMBO_CARDS: [
        {name: "Air Horn", image: "comboc_airhorn_result.jpg", filteredImage: "comboc_airhorn_no_result.jpg", items: ["Pylon", "Spray Paint"]},
        {name: "Auger", image: "comboc_auger_result.jpg", filteredImage: "comboc_auger_no_result.jpg", items: ["Drill Motor", "Pitchfork"]},
        {name: "Beer Hat", image: "comboc_beerhat_result.jpg", filteredImage: "comboc_beerhat_no_result.jpg", items: ["Construction Hat", "Beer"]},
        {name: "Blazing Aces", image: "comboc_blazingace_result.jpg", filteredImage: "comboc_blazingace_no_result.jpg", items: ["Tennis Racquet", "Tiki Torch"]},
        {name: "Blitzkrieg", image: "comboc_blitzkrieg_result.jpg", filteredImage: "comboc_blitzkrieg_no_result.jpg", items: ["Merc Assault Rifle", "Electric Chair" ]},
        {name: "Boomstick", image: "comboc_boomstick_result.jpg", filteredImage: "comboc_boomstick_no_result.jpg", items: ["Pitchfork", "Shotgun"]},
        {name: "Burning Skull", image: "comboc_burningskull_result.jpg", filteredImage: "comboc_burningskull_no_result.jpg", items: ["Bull Skull", "Motor Oil"]},
        {name: "Defiler", image: "comboc_defiler_result.jpg", filteredImage: "comboc_defiler_no_result.jpg", items: ["Sledge Hammer", "Fire Axe"]},
        {name: "Drill Bucket", image: "comboc_drillbucket_result.jpg", filteredImage: "comboc_drillbucket_no_result.jpg", items: ["Power Drill", "Bucket"]},
        {name: "Driller", image: "comboc_driller_result.jpg", filteredImage: "comboc_driller_no_result.jpg", items: ["Power Drill", "Spear"]},
        {name: "Dynameat", image: "comboc_dynameat_result.jpg", filteredImage: "comboc_dynameat_no_result.jpg", items: ["Hunk of Meat", "Dynamite"]},
        {name: "Electric Chair", image: "comboc_electrocutionchair_result.jpg", filteredImage: "comboc_electrocutionchair_no_result.jpg", items: ["Wheelchair", "Battery"]},
        {name: "Electric Rake", image: "comboc_electricrake_result.jpg", filteredImage: "comboc_electricrake_no_result.jpg", items: ["Battery", "Leaf Rake"]},
        {name: "Exsanguinator", image: "comboc_exsanguinator_result.jpg", filteredImage: "comboc_exsanguinator_no_result.jpg", items: ["Vacuum Cleaner", "Saw Blade"]},
        {name: "Fire Spitter", image: "comboc_firespitter_result.jpg", filteredImage: "comboc_firespitter_no_result.jpg", items: ["Toy Spitball Gun", "Tiki Torch"]},
        {name: "Flamethrower", image: "comboc_flamethrower_result.jpg", filteredImage: "comboc_flamethrower_no_result.jpg", items: ["Water Gun", "Gasoline Canister"]},
        {name: "Flaming Gloves", image: "comboc_flaminggloves_result.jpg", filteredImage: "comboc_flaminggloves_no_result.jpg", items: ["Boxing Gloves", "Motor Oil"]},
        {name: "Fountain Lizard", image: "comboc_fountainlizard_result.jpg", filteredImage: "comboc_fountainlizard_no_result.jpg", items: ["Lizard Mask", "Fountain Firework"]},
        {name: "Freedom Bear", image: "comboc_freedombear_result.jpg", filteredImage: "comboc_freedombear_no_result.jpg", items: ["Robot Bear", "LMG"]},
        {name: "Freezer Bomb", image: "comboc_freezerbomb_result.jpg", filteredImage: "comboc_freezerbomb_no_result.jpg", items: ["Fire Extinguisher", "Dynamite"]},
        {name: "Gem Blower", image: "comboc_gemblower_result.jpg", filteredImage: "comboc_gemblower_no_result.jpg", items: ["Gems", "Leaf Blower"]},
        {name: "Hacker", image: "comboc_hacker_result.jpg", filteredImage: "comboc_hacker_no_result.jpg", items: ["Computer Case", "Flashlight"]},
        {name: "Hail Mary", image: "comboc_hailmary_result.jpg", filteredImage: "comboc_hailmary_no_result.jpg", items: ["Football", "Grenade"]},
        {name: "Handy Chipper", image: "comboc_handychipper_result.jpg", filteredImage: "comboc_handychipper_no_result.jpg", items: ["Wheelchair", "Lawn Mower"]},
        {name: "Heliblade", image: "comboc_heliblade_result.jpg", filteredImage: "comboc_heliblade_no_result.jpg", items: ["Toy Helicopter", "Machete",]},
        {name: "Holy Arms", image: "comboc_holyarms_result.jpg", filteredImage: "comboc_holyarms_no_result.jpg", items: ["Training Sword", "Box of Nails"]},
        {name: "I.E.D.", image: "comboc_ied_result.jpg", filteredImage: "comboc_ied_no_result.jpg", items: ["Box of Nails", "Propane Tank"]},
        {name: "Infernal Arms", image: "comboc_infernalarms_result.jpg", filteredImage: "comboc_infernalarms_no_result.jpg", items: ["Training Sword", "Motor Oil"]},
        {name: "Knife Gloves", image: "comboc_knifegloves_result.jpg", filteredImage: "comboc_knifegloves_no_result.jpg", items: ["Bowie Knife", "Boxing Gloves"]},
        {name: "Laser Sword", image: "comboc_lasersword_result.jpg", filteredImage: "comboc_lasersword_no_result.jpg", items: ["Gems", "Flashlight"]},
        {name: "Molotov", image: "comboc_molotov_result.jpg", filteredImage: "comboc_molotov_no_result.jpg", items: ["Whiskey", "Newspaper"]},
        {name: "Paddlesaw", image: "comboc_paddlesaw_result.jpg", filteredImage: "comboc_paddlesaw_no_result.jpg", items: ["Paddle", "Chainsaw"]},
        {name: "Parablower", image: "comboc_parablower_result.jpg", filteredImage: "comboc_parablower_no_result.jpg", items: ["Leaf Blower", "Parasol"]},
        {name: "Plate Launcher", image: "comboc_platelauncher_result.jpg", filteredImage: "comboc_platelauncher_no_result.jpg", items: ["Plates", "Cement Saw"]},
        {name: "Pole Weapon", image: "comboc_poleweapon_result.jpg", filteredImage: "comboc_poleweapon_no_result.jpg", items: ["Push Broom", "Machete"]},
        {name: "Porta Mower", image: "comboc_portamower_result.jpg", filteredImage: "comboc_portamower_no_result.jpg", items: ["2x4", "Lawn Mower"]},
        {name: "Power Guitar", image: "comboc_powerguitar_result.jpg", filteredImage: "comboc_powerguitar_no_result.jpg", items: ["Electric Guitar", "Amplifier"]},
        {name: "Ripper", image: "comboc_ripper_result.jpg", filteredImage: "comboc_ripper_no_result.jpg", items: ["Cement Saw", "Saw Blade"]},
        {name: "Roaring Thunder", long: true, image: "comboc_roaringthunder_result.jpg", filteredImage: "comboc_roaringthunder_no_result.jpg", items: ["Battery", "Goblin Mask"]},
        {name: "Rocket Launcher", long: true, image: "comboc_rocketlauncher_result.jpg", filteredImage: "comboc_rocketlauncher_no_result.jpg", items: ["Rocket Fireworks", "Lead Pipe"]},
        {name: "Spear Launcher", long: true, image: "comboc_spearlauncher_result.jpg", filteredImage: "comboc_spearlauncher_no_result.jpg", items: ["Leaf Blower", "Spear"]},
        {name: "Spiked Bat", image: "comboc_spikedbat_result.jpg", filteredImage: "comboc_spikedbat_no_result.jpg", items: ["Baseball Bat", "Box of Nails"]},
        {name: "Sticky Bomb", image: "comboc_stickybomb_result.jpg", filteredImage: "comboc_stickybomb_no_result.jpg", items: ["Lawn Dart", "Dynamite"]},
        {name: "Super B.F.G.", image: "comboc_superbfg_result.jpg", filteredImage: "comboc_superbfg_no_result.jpg", items: ["Blast Frequency Gun", "Amplifier"]},
        {name: "Super Slicer", image: "comboc_superslicer_result.jpg", filteredImage: "comboc_superslicer_no_result.jpg", items: ["Servbot Mask", "Lawn Mower"]},
        {name: "Tenderizers", image: "comboc_tenderizers_result.jpg", filteredImage: "comboc_tenderizers_no_result.jpg", items: ["MMA Gloves", "Box of Nails"]},
        {name: "Tesla Ball", image: "comboc_teslaball_result.jpg", filteredImage: "comboc_teslaball_no_result.jpg", items: ["Bingo Ball Cage", "Battery"]},
        {name: "Wingman", image: "comboc_wingman_result.jpg", filteredImage: "comboc_wingman_no_result.jpg", items: ["Queen", "Nectar"] }
    ],
};